<!-- @format -->

<template>
  <div
    class="flex justify-between p-2"
    :style="{ pointerEvents: isDisabled ? 'none' : 'auto' }"
    :class="
      !isDisabled
        ? placeholder && !selectedDateValue
          ? 'text-gray-500'
          : 'text-black'
        : needDisableColor
        ? 'bg-teal bg-opacity-5'
        : 'disabled-input'
    "
  >
    {{
      moment(selectedDateValue).format("MMM DD, YYYY") === "Invalid date"
        ? placeholder
        : moment(selectedDateValue).format("MMM DD, YYYY")
    }}
    <input
      type="date"
      v-model="selectedDateValue"
      class="rounded text-white focus:outline-none"
      :min="needMinDate ? formatDate(minDate) : ''"
      :max="needMaxDate ? formatDate(maxDate) : ''"
      :class="
        !isDisabled
          ? ''
          : needDisableColor
          ? 'bg-teal bg-opacity-5'
          : 'disabled-input'
      "
      @change="handleDateChange"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, toRefs } from "vue";
import moment from "moment";

const props = defineProps({
  placeholder: {
    type: String,
    required: true,
  },
  selectedDate: {
    type: Date,
  },
  isDisabled: {
    type: Boolean,
  },
  needDisableColor: {
    type: Boolean,
  },
  needMinDate: {
    type: Boolean,
    default: false,
  },
  minDate: {
    type: Date,
    default: new Date(),
  },
  needMaxDate: {
    type: Boolean,
    default: false,
  },
  maxDate: {
    type: Date,
    default: new Date(),
  },
  enableWeekendCheck: {
    type: Boolean,
    default: false,
  },
});

const {
  placeholder,
  selectedDate,
  needMinDate,
  minDate,
  needMaxDate,
  maxDate,
  enableWeekendCheck,
} = toRefs(props);
const emits = defineEmits([
  "cleared-date",
  "selected-date",
  "weakday-selected",
]);
const selectedDateValue = ref(selectedDate.value || "");

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function handleDateChange(event) {
  const newValue = event.target.value;
  const selectedDate = new Date(newValue);

  if (enableWeekendCheck.value) {
    const day = selectedDate.getDay();

    if (day === 0 || day === 6) {
      emits("weakday-selected");
      selectedDateValue.value = "";
      emits("cleared-date");
      return;
    }
  }

  if (!newValue) {
    emits("cleared-date");
  } else {
    selectedDateValue.value = newValue;
    emits("selected-date", newValue);
  }
}
</script>

<style scoped>
.disabled-input {
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
}
::-webkit-calendar-picker-indicator {
  background-image: url("../../../assets/icons/calendar_month.svg");
}
input[type="date"]::-webkit-datetime-edit {
  display: none;
}
</style>
